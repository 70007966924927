const Quid: React.FC = () => {
  return (
    <svg
      width="35"
      height="55"
      viewBox="0 0 35 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.15 0C7.68874 0 0 7.69113 0 17.1323C0 26.5966 7.68874 34.2877 17.15 34.2877C18.1399 34.2877 19.1298 34.1956 20.0966 34.0344C21.8001 33.735 22.9281 32.1231 22.6519 30.4191C22.3526 28.7151 20.7412 27.5867 19.0377 27.8631C18.4161 27.9782 17.7946 28.0243 17.15 28.0243C11.1418 28.0243 6.26149 23.1425 6.26149 17.1323C6.26149 11.1222 11.1418 6.24041 17.15 6.24041C23.1583 6.24041 28.0386 11.1222 28.0386 17.1323V51.5121C28.0386 53.2392 29.4428 54.6439 31.1693 54.6439C32.8958 54.6439 34.3001 53.2392 34.3001 51.5121V17.1323C34.2771 7.69113 26.5883 0 17.15 0Z"
        fill="#A22FBC"
      />
    </svg>
  );
};

export default Quid;
