import React, { ReactNode } from "react";
import { ColDef } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { Profile } from "entities/clients/ProfileEntity";
import DetailButton from "components/atoms/DetailButton";

interface ProfilesProp {
  profiles?: Profile[];
  children?: ReactNode | ReactNode[];
  loading: boolean;
}

const CustomerProfilesQuid: React.FC<ProfilesProp> = ({
  profiles,
  loading,
}) => {
  const { t } = useTranslation("profiles");
  const filteredProfiles = profiles?.filter((profile: Profile) => {
    return profile.type === "COMPANY";
  });

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: t("profile_id"),
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "partyId",
      headerName: t("profile_partyId"),
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: t("profile_name"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "realationship",
      headerName: t("profile_realationship"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "default",
      headerName: t("profile_default"),
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "address.country",
      headerName: t("profile_country"),
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "",
      headerName: t("profile_Details"),
      renderCell: (params) => (
        <DetailButton to={`/companies/${params?.row?.partyId}`} />
      ),
    },
  ];

  return (
    <>
      <QuidDataGrid
        rows={filteredProfiles || []}
        columns={columns}
        loading={loading}
        rowCount={filteredProfiles?.length}
        pageSize={filteredProfiles?.length}
        hideFooter={true}
      />
    </>
  );
};

export default CustomerProfilesQuid;
